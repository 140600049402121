
import { api, itemsPerPage, totalItems  } from '../api/api';

import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import imageOne from '../image/one.jpg';
import imageTwo from '../image/two.jpg';
import imageThree from '../image/four.jpg';

;

const Home = () => {
   
    
   
  return (
    <>
     <div className="container">
        <div className="row">
            <div className="col-lg-12">
            <section className='carousel'>
      <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval={10000}>
      <img src={imageOne} className="d-block w-100" width="400" height="600" alt="My Image" />
    </div>
    <div className="carousel-item" data-bs-interval={2000}>
      <img src={imageTwo} className="d-block w-100" width="400" height="600" alt="My Image" />
    </div>
    <div className="carousel-item">
      <img src={imageThree} className="d-block w-100" width="400" height="600" alt="My Image" />
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

      </section>
            </div>
        </div>
     </div>
      <div className="container mt-5">
        <h2 className='text-center mt-4 bg-dark text-white p-4'>Best Seller Beauty Product's in 2024</h2>
        <div className="row">
           
           
        {api.slice(0, 10).map((item) =>(
        <div className="col-lg-6 mt-4">
        
              <div key={item.id} className="card mb-3 shadow-lg" style={{maxWidth: 540}}>
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={item.image} className="img-fluid rounded-start p-3" alt="..."  />
                  <p className='card-title text-center mt-4 fw-bold'>Price: {item.price}</p>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h6 className="card-title fw-bold">Name: {item.name}</h6>
                    <p className="card-text fw-bold">Brand: {item.Brand}</p>
                    
                    <Link to={`/detail/${item.id}`}> <button type="button" class="btn btn-dark">Details</button></Link>
                    <a  className="ms-5" href={item.link} target="_blank"><button type="button" class="btn btn-dark"> Buy</button></a>
                  </div>
                  
               
              </div>
              </div>
            
              </div>
        
      
  
  
  </div>
))}

 
  

   
           
        </div>
       
        
      </div>
    
    

    </>
  )
 
}

export default Home
