// src/api.js
export const api = [
    {
        id: 1,
      name: 'Paulas Choice--SKIN PERFECTING 2% BHA Liquid Salicylic Acid Exfoliant--Facial Exfoliant for Blackheads, Enlarged Pores, Wrinkles & Fine Lines, 4 oz Bottle',
      image: 'https://m.media-amazon.com/images/I/61bLRVPWH7L._SL1500_.jpg',
      price: '$35.00',
      Brand:	"Paula's Choice",
      Item: 'Form	Liquid',
      link: 'https://ln.run/If54M',
      Skin_Type:	'Oily, Combination, Dry, Normal',
      Product_Benefits:	'Exfoliating, Pore Treatment, Radiant Skin, Blemish Control, Smoothening',
      Active_Ingredients:	'Salicylic Acid, salicylic_acid',
      desc: "GENTLE NON-ABRASIVE LEAVE-ON EXFOLIANT: With 2% BHA (Beta hydroxy acid), to unclog & diminish enlarged pores, exfoliate dead skin cells, smooth wrinkles, and brighten and even out skin tone. Combats redness, wrinkles, aging, enlarged pores, and blackheads.\
Dramatically improves skin's texture for radiant youthful even-toned skin. Beta hydroxy acid (BHA, also known as salicylic acid) mimics the natural exfoliation process of younger skin by helping it shed extra layers while unclogging and clearing pores.\
HYDRATE, BRIGHTEN & SMOOTH FINE LINES: Our unique, non-abrasive, leave-on exfoliant is gentle enough for daily use on all skin types. Exfoliates dead skin cells while clearing pores for a more even tone, plus it visibly reduces fine lines & wrinkles.\
Paula's Choice facial exfoliants are gentler on delicate skin of the face & neck than an abrasive face scrub, which can cause micro-tears. A BHA leave-on exfoliator delivers hydration & can exfoliate directly inside the pore & improve how the pore works.\
Paula's Choice Skincare makes products that work. No fragrance, no parabens, no fluff. Just effective, science-backed formulas that target any concern from wrinkles to breakouts."
    },
    {
        id:2,
      name: 'RoC Retinol Correxion Value Set Duo, Deep Wrinkle Anti-Aging Night Face Cream (10 fl oz) + Daily Under Eye Cream for Dark Circles & Puffiness (.5 fl oz), Skin Care Set (Packaging May Vary)',
      image: 'https://m.media-amazon.com/images/I/61PSfizDiWL._SY355_.jpg',
      Item: '2 x 4.5 x 6.8 inches',
      price: '$35.00',
      link: 'https://shorturl.at/LagOF',
      Brand: 'RoC',
      Product_Benefits: '',
      Skin_Type: '',
      Active_Ingredients: '',
      desc: "BOOST YOUR RETINOL WRINKLE REGIMEN: Address 3 major eye concerns - puffiness, dark circles & wrinkles - with RoC Eye Cream. Wake up to revitalized, hydrated skin for a smoother, more radiant appearance with RoC Night Cream. Powered by RoC Retinol.\
PROVEN RESULTS: Retinol Correxion Night Cream is clinically proven to visibly reduce signs of photodamage in 4 weeks. In 12 weeks, wrinkles are visibly reduced by up to 50%, even deep wrinkles.*. Retinol Correxion Eye Cream is clinically proven to visibly reduce crow's feet & wrinkles by 50% in 12 weeks*.\
ANTI-AGING FORMULAS: Whether you're looking for a solution for deep wrinkles, fine lines, dark circles, crow's feet, uneven skin tone or dry skin, we have an anti-aging formula that's right for you.\
SCIENCE & BEAUTY: We produce a wide array of skin care products & treatments including daily moisturizers with SPF, hyaluronic and niacinamide acids, facial cleansers, vitamin C serums, retinol skin tone & wrinkle regimens & creams for under eyes, neck firming, decollete & much more.\
SKIN CARE INNOVATION: Our products are recognized by beauty industry leaders & dermatologists for their best-in-class effectiveness. We continue to research & innovate new formulas for healthier skin. *Based on an eight week clinical study."
    },
    {
      id:3,
      name: 'IOPE Super Vital Skin Care Set - Luxury Korean Skincare Gift Set for Anti Aging, Including Face Toner, Lotion and Moisturizer for Wrinkle Care - Facial Care Kit for All Skin, for Hydration & Lifting',
      image: 'https://m.media-amazon.com/images/I/71rdpZZb5XL._SL1500_.jpg',
      Item: 'Lotion',
      link: 'https://shorturl.at/T2Lu1',
      Brand: 'IOPE',
      price: '119.00',
      Active_Ingredients: '',
      Product_Benefits:'Hydrating, Soothing, Moisturizing, Softening, Revitalization, Rejuvenation',
      Skin_Type: 'Oily, Combination, Sensitive, Dry, Normal',
      desc: "[ Luxury Korean Skincare Set, Perfect for Gift ] : Our powerful duo contains plant-driven energy to deliver abundant hydration and nourishing ingredients. The softener tones the skin's texture with rich moisture, while the emulsion strengthens the skin barrier and boost firmness.\
[ Patented Anti-Aging Ingredients For Skin ] : Super Flavonoids, our proprietary formula containing powerful plants and antioxidants, recharge tired skin with moisture and nutrition to achieve firm skin. Advanced Bio-Seletinoid protects skin from free radicals and provides focused treatment on dryness, fine lines and all signs of skin aging.\
[ Quick Absorption With Non-Sticky Formula ] : Formulated with the nourishing ingredient, Shea butter, it is easily absorbed and hydrates the skin by locking in moisture. The soft and moist formula keeps the skin hydrated all day long.\
[ Clinically Tested Hydration & Anti-Aging Effect ] : IOPE products are backed by science and research. Within one week, skin feels softer and smoother to touch. In 4 weeks, lines and wrinkles appear more filled. Skin appears moist and radiant with a youthful glow. (Individual results may differ)\
[ All-in-One Set for Full Skin Care Routine ] : For you and your loved ones, this gift set is packed with entire skin regimen. It contains a full size 5.07oz Super Vital Softener and Emulsion along with a sample-sized 0.6oz Softener and Emulsion, and a 0.5oz Super Vital Cream.",
    },
    {
        id: 4,
      name: ' SimplyVital Face Moisturizer Collagen Cream - Anti Aging Neck and Décolleté - Made in USA Day & Night Face Cream - Moisturizing, Lifting & Recovery – 1.7oz',
      image: 'https://m.media-amazon.com/images/I/71ibBNNNd5L._AC_UL960_FMwebp_QL65_.jpg',
      Item: '1.7 Fluid Ounces',
      Brand: 'SimplyVital',
      link: 'https://shorturl.at/xa2hD',
      price: '$22.89',
      Active_Ingredients: 'hyaluronic_acid, vitamin_e, Hydrolyzed Marine Collagen, Retinol, Hyaluronic Acid',
      Product_Benefits: 'Natural Ingredients',
      Skin_Type: '',
      desc: "Ultimate Anti-Aging Cream - Our powerful skincare formula with retinol, collagen, and hyaluronic acid works to diminish the signs of aging and keep your skin moisturized and filled with a youthful glow. Our moisturizer helps boost skin renewal and elasticity, restoring texture and smoothing out fine lines and wrinkles. Enjoy the premium skincare experience and a powerful anti-aging therapy!\
Powerful Retinol Face Cream - With the maximum concentration of retinol in the formula, our moisturizer provides gentle, but effective retinol treatment, working on rejuvenating and rebuilding your skin from the deepest layers to the surface. You will also feel your skin getting softer and more hydrated because of hyaluronic acid, working with retinol to keep your skin moisturized on all levels.\
Natural Collagen Moisturizer - Your skin may start not looking its best with age due to slow collagen production. Our formula will give you nourishment with natural ingredients and the double power collagen boost - by nourishing skin with collagen in the formula as well as kickstarting its natural production - our cream ensures your skin can build itself to its full glory and radiance!\
Premium Face Cream Made In USA - SimplyVital ensures the highest quality of each bottle of retinol moisturizer we produce. We always choose the most powerful and gentle natural formulas, so you can always get what your skin truly deserves and let your natural beauty shine. Our skincare products are made in USA GMP-certified laboratories with the highest manufacturing standards.\
Your Happiness Guaranteed - At SimplyVital, we value your beauty and satisfaction the most. We proudly produce our skincare using the best manufacturing practices, the newest beauty technologies, the help of the best American scientists, providers, and formulators, and natural ingredients. If you are somewhat unsatisfied with your purchase, please contact us, and we'll make things right for you!",
    },
    {
        id:5,
        name: 'VI DERM Age-Defying Treatment Moisturizer, With Retinol, Vitamin C, and Green Tea, Enhances Collagen Production, 1.7 Fl Oz / 50 mL ',
        image: 'https://m.media-amazon.com/images/I/51gJe3p3qaL._SY355_.jpg',
        Item: '1.7 Fluid Ounces',
        Brand: 'VI DERM',
        price: '$110.00',
        link: 'https://shorturl.at/iIwKY',
        Active_Ingredients: '',
        Product_Benefits: 'Re',
        Skin_Type: '',
        desc: "GLYCOLIC ACID 10%: Resurfaces skin's texture and tone\
Retinol Stimulates cell turnover to help minimize signs of aging, visibly reduce pore size, and clear away dead skin cells that can lead to breakouts.\
THD Ascorbate (Vitamin C) A stable and more efficacious form of Vitamin C that enhances collagen production, defends against free radical damage, and brightens for a more radiant complexion.\
Aloe Vera Soothes, softens, hydrates, and prevents Trans-Epidermal Water Loss.\
Cruelty Free, Paraben Free, Fragrance Free, Gluten Free, Vegan",
    },
    {
        id:6,
        name: ' TruSkin Face Serum Trio – Hyaluronic Acid, Vitamin C & Retinol Serum for Face – Anti Aging Skin Care Set for Women – Skin Care for Bright, Smooth, Firm & Hydrated Skin, 1 fl oz, 3 Bottles',
        image: 'https://m.media-amazon.com/images/I/719DdWXQuiL._SX355_.jpg',
        Item: '',
        link: 'https://rb.gy/98hw2y',
        Brand: '	TruSkin Naturals',
        price: '$44.95',
        Active_Ingredients: 'collagen, antioxidants , hyaluronic_acid, retinol, Hyaluronic Acid Vitamin C Retinol',
        Product_Benefits: 'Anti-aging,Brighten,Even Skin Tone,Hydrating,Moisturizing,Plumps,Smoothening,Soothing',
        Skin_Type: 'All',
        desc: "BRIGHTEN: Vitamin C Serum for face blends Vitamin C with Hyaluronic Acid and MSM; helps even skin tone, neutralize free radicals and support collagen to improve radiance and reduce dark spots, fine lines and wrinkles\
RENEW: Retinol Serum for face with retinoid, Vitamin A, plus Vitamin E, Gotu Kola and Hyaluronic Acid; anti aging serum helps skin tone, texture, hyperpigmentation and hydration; first-time retinol users should apply only 2-3 times per week\
HYDRATE: Hyaluronic Acid Serum for face intensely hydrates and softly plumps to improve suppleness, texture and tone; infused with Gotu Kola (aka Cica) to enhance hydration and firmness, plus Vitamin C & Aloe Vera to boost luminosity and soothe skin\
HOW TO USE: Vitamin C or Hyaluronic Acid Serum, apply 3-5 drops of serum to clean skin morning or night daily; for best results, follow with moisturizer; Retinol Serum, start by applying a few drops 2-3 nights a week and use SPF during the day; Patch test facial skin care products 24 hours before use; avoid contact with eyes, serums for skin care for external use only\
THE TRUSKIN DIFFERENCE: If for any reason you decide this product isn't a fit for your skin, we're here for you. Each purposefully balanced medley of key antioxidants, super-nutrients and nourishing botanical actives enhances efficacy and delivers skin-friendly benefits, without additives or fragrances",
    },
    {
        id: 7,
        name: 'The Face Shop Rice Water Bright Foaming Facial Cleanser with Ceramide, Gentle Face Wash for Hydrating & Moisturizing, Vegan Face Cleanser, Makeup Remover, Korean Skin Care for All Skin Types ',
        image: 'https://m.media-amazon.com/images/I/61OfaXa8ERL._SL1500_.jpg',
        Item: '',
        link: 'https://rb.gy/32er6p',
        Brand: 'The Face Shop',
        price: '$12.00',
        Active_Ingredients: '',
        Product_Benefits: '',
        Skin_Type: '',
        desc: '',
    },
    {
        id:8,
        name: 'Buttah Skin Supreme Kit for Melanin Rich Skin | CocoShea Revitalizing Cream 2 oz | Vitamin C Serum 1 oz | Cleanser 3.4 oz | Rosewater Toner 3.4 oz | Black Owned Skincare ',
        image: 'https://m.media-amazon.com/images/I/713hSOufRPL._SL1500_.jpg',
        Item: 'Cream',
        Brand: 'buttah. by Dorion Renaud',
        price: '$79.00',
        link: 'https://rb.gy/d7tal5',
        Active_Ingredients: '',
        Product_Benefits: 'Cleansing, toning, exfoliating, protecting, and moisturizing skin',
        Skin_Type: 'All',
        desc: 'SUPREME SKINCARE KIT – Our Supreme kit of HERO products work together to cleanse, tone, exfoliate, protect, and moisturize skin daily. The complete set is the perfect skin care set for men and women alike.\
GENTLE AND EFFECTIVE CLEANSING - Our daily gel cleanser gently lifts away dirt, oil, debris and radiance-dulling surface cells for smoother, brighter, healthy skin, without stripping skin of it’s natural oils.\
MULTI-FUNCTION TONER HYDRATES, CLEANS, & CALMS SKIN - After cleansing, saturate a cotton round with our refreshing toner and sweep across the face, neck and décolletage, avoiding the eye area, and enjoy the soothing scents of Damascus Rose and calming lavender. This facial toner leaves your skin feeling fresh, energized and ready to absorb more Buttah goodness. Our Damascus Rose toner is perfect for your morning and night routine.\
BRIGHTEN AND SMOOTH WITH VITAMIN C - Named “Magic in a Bottle” by our Buttah family, this serum is perfect for melanin rich skin. This brightening serum targets dryness, excessive oiliness, imperfections, hyperpigmentation, dark spots and inflammation for bright, vibrant, truly healthy skin.\
SUPER-CHARGED NOURISHING FORMULA - Our light-as-air face moisturizer blends deeply moisturizing Hyaluronic Acid and antioxidant and Vitamin E packed Sunflower Seed Oil to restore a radiant complexion and healthy looking glow.',
    },
    {
        id:9,
        name: 'Westmore Beauty Body Coverage Perfector 3.5 Oz/ 74ml (Natural Radiance) - Waterproof Leg And Body Makeup For Tattoo Cover Up And More - The Best Tattoo Cover Up Leg Makeup',
        image: 'https://m.media-amazon.com/images/I/51K2B8r-A9L._SL1000_.jpg',
        Item: '	Cream',
      Brand: '',
      price: '$34.98',
      link: 'https://rb.gy/gijmyz',
      Active_Ingredients: '',
      Product_Benefits: '',
      Skin_Type: 'Combination, Sensitive, Normal',
      desc: 'EXCEPTIONAL COVERAGE: Want flawless-looking skin from head to toe? Body Coverage Perfector’s lightweight, natural-looking formula instantly evens your skin tone and conceals everything from stretch marks, spots and scars to veins and tattoos.\
NATURAL-LOOKING RADIANT FINISH: A combination of treated pigments and light-reflecting minerals suspended in a flexible matrix provide natural-looking coverage that moves with your skin, never feeling heavy or cakey.\
BUDGE-PROOF WEAR: Unlike traditional leg makeup and body makeup, Body Coverage Perfector is Waterproof, Sweatproof, Transfer-Resistant and lasts for over 24 hours.\
EXCLUSIVE: Tri-Effects Technology that delivers long lasting coverage that looks and feels like skin. Follow the usage instructions below for best results.\
11 BUILDABLE SHADES: Whether you want to match to your skin tone or get a bronzed look without self-tanner - we’ve got you covered. Our shades range from fair to deep to deliver a perfect match for your skin tone.',
    },
    {
        id: 10,
        name: ' City Beauty City Lips Clear - Plumping Lip Gloss - Hydrate & Volumize - High Shine Lip Plumper - Hyaluronic Acid & Peptides Visibly Smooth Lip Wrinkles - Cruelty-Free',
        image: 'https://m.media-amazon.com/images/I/419kkh1p7vS._SL1500_.jpg',
        Item: 'Liquid',
        Brand: 'City Beauty',
        price: '$35.00',
        link: 'https://rb.gy/39rldw',
        Active_Ingredients: 'Hyaluronic Acid',
        Product_Benefits: '',
        Skin_Type: '',
        desc: 'This product is from a small business brand. Support small.',
    }
   
  ];

  export const itemsPerPage = 10;
  export const totalItems = api.length;
  export const totalPages = Math.ceil(totalItems / itemsPerPage);