import React from 'react'
import { useParams } from 'react-router-dom';
import { api } from '../api/api';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage
} from 'mdb-react-ui-kit';


const DetailPage = () => {
    const { id } = useParams();
  const item = api.find(i => i.id === parseInt(id));

  if (!item) {
    return <div>Item not found</div>;
  }
  return (
    <>
   
  <div className="container mt-5 ">
    <div className="row">
      <div className="col-lg-6 ">
      <MDBCard className='mb-3 shadow-lg p-4'>
        <MDBCardImage position='top' src={item.image} alt='...' />
        
      </MDBCard>
      </div>
      <div className="col-lg-4">
      <div class="card-body shadow-lg p-5">
    <h5 class="card-title fw-bold">Name: {item.name}</h5>
    <p class="card-text fw-bold">Brand: {item.Brand}</p>
    <p class="card-text fw-bold">Price: {item.price}</p>
    <p class="card-text fw-bold">Item: {item.Item}</p>
    <p class="card-text fw-bold">Product_Benefits: {item.Product_Benefits}</p>
    <p class="card-text fw-bold">Skin_Type: {item.Skin_Type}</p>
    <p class="card-text fw-bold">Active_Ingredients: {item.Active_Ingredients}</p>
    <a  className="ms-5" href={item.link} target="_blank"><button type="button" class="btn btn-dark"> Buy</button></a>
    

    
  </div>
      </div>
    </div>
    <div className="row mb-5">
      <div className="col-lg-12 shadow-lg bg-dark text-white">
        <div className="card-body">
        <div className="card-title text-center fw-bold fst-italic mt-2">About this item</div>
        <div className="card-text p-4 m-2">
          {item.desc}
        </div>
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default DetailPage
