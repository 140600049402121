import React from 'react'
import { Link } from 'react-router-dom';


const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg body-white sticky-top bg-dark">
  <div className="container">
    <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon text-white" />
    </button>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
      <Link className="navbar-brand text-white" to="/">Beauty356</Link>
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
        <li className="nav-item">
          <Link className="nav-link active text-white me-5" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active text-white me-5" aria-current="page" to="/blog">Blog</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active text-white me-5" aria-current="page" to="/about">About</Link>
        </li>
        {/*  */}
        
      </ul>
      
    </div>
  </div>
</nav>

    </>
  )
}

export default Header
