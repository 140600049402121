
import { api, itemsPerPage, totalItems  } from '../api/api';

import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
const Blog = () => {
  return (
    <>
       <div className="container ">
       <h2 className='text-center mt-4 bg-dark text-white p-4'>Best Seller Beauty Product's in 2024</h2>
        <div className="row">
           
           
        {api.slice(0, 10).map((item) =>(
        
        
        <div className="col-lg-6 mt-4">
        
        <div key={item.id} className="card mb-3 shadow-lg" style={{maxWidth: 540}}>
        <div className="row g-0">
          <div className="col-md-4">
            <img src={item.image} className="img-fluid rounded-start p-3" alt="..."  />
            <p className='card-title text-center mt-4 fw-bold'>Price: {item.price}</p>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h6 className="card-title fw-bold">Name: {item.name}</h6>
              <p className="card-text fw-bold">Brand: {item.Brand}</p>
              
              <Link to={`/detail/${item.id}`}> <button type="button" class="btn btn-dark">Details</button></Link>
              <a  className="ms-5" href={item.link} target="_blank"><button type="button" class="btn btn-dark"> Buy</button></a>
            </div>
            
         
        </div>
        </div>
      
        </div>
  



</div>
        
      
  
  
  
))}

 
  

   
           
        </div>
       
        
      </div>
    </>
  )
}

export default Blog
