import React from 'react'

const About = () => {
  return (
    <>
      <div className="container mt-5">
        <h2 className='text-center mt-5 mb-3'>About Us</h2>
      <div class="row">
  <div class="col-sm-5 mb-3 mb-sm-0">
    <div class="">
      <div class="card-body">
        <h3 class="card-title">Elevating your beauty, one product at a time</h3>
       
      </div>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="bg-dark text-white p-4">
      <div class="card-body">
       
        <p class="card-text text-start p-0">Clearly state the mission and purpose of the blog - to empower readers to feel confident and beautiful in their own skin.
Emphasize the blog's focus on reviewing and recommending the best beauty products for all skin types and concerns.

Highlight the blog's specialty in covering the latest makeup trends, effective skincare routines, and providing beauty tutorials and tips.
Mention that the blog caters to both beginners and seasoned beauty enthusiasts.
.</p>
        
      </div>
    </div>
  </div>
</div>
      </div>
      <div className="container mt-5">
      <div class="row ">
  <div class="col-sm-6 mb-3 mb-sm-0">
    <div class=" mb-5 mt-5 bg-dark text-white p-4">
      <div class="card-body">
        <h5 class="card-title mb-3">What We Do</h5>
        <p class="card-text">At Beautyblog, we're dedicated to empowering our readers to feel confident and beautiful in their own skin. As a team of beauty enthusiasts, makeup artists, and skincare experts, we're passionate about sharing our knowledge and helping you navigate the ever-evolving world of beauty.</p>
        
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="mb-5 mt-5">
      <div class="card-body">
        <h5 class="card-title mb-3">Product Reviews and Recommendations</h5>
        <p class="card-text">Our primary focus is providing honest, in-depth reviews of the latest and greatest beauty products on the market. From the newest makeup trends to the most effective skincare routines, we test and analyze a wide range of products to help you make informed purchasing decisions.</p>
       
      </div>
    </div>
  </div>
  <div class="col-sm-6 mb-3 mb-sm-0">
    <div class="mb-5 mt-5">
      <div class="card-body">
        <h5 class="card-title mb-3">Beauty Tutorials and Tips</h5>
        <p class="card-text">In addition to product reviews, we offer step-by-step tutorials and practical tips to help you master various beauty techniques. Whether you're a seasoned pro or a beauty beginner, you'll find valuable information to elevate your beauty routine and boost your confidence.</p>
     
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="mb-5 mt-5">
      <div class="card-body bg-dark text-white p-4">
        <h5 class="card-title mb-3">Skincare Expertise</h5>
        <p class="card-text">Healthy, glowing skin is the foundation of true beauty, which is why we place a strong emphasis on skincare. Our team of experts shares their knowledge on the best ingredients, tailored routines, and skincare hacks to address a variety of skin concerns..</p>
       
      </div>
    </div>
  </div>
  <div class="col-sm-6 mb-3 mb-sm-0">
    <div class="mb-5 mt-5">
      <div class="card-body bg-dark text-white p-4">
        <h5 class="card-title mb-3">Inspiration and Empowerment</h5>
        <p class="card-text">Beyond just product recommendations and technical advice, we aim to inspire and empower our readers to embrace their unique features and feel beautiful in their own skin. Our content celebrates diversity, self-love, and the transformative power of beauty.</p>
       
      </div>
    </div>
  </div>
  
</div>
      </div>
    </>
  )
}

export default About
